import tw from "twin.macro"

export const H4 = tw.h4`
 text-header 
 font-monospace 
 text-center 
 uppercase 
 tracking-widest 
 m-2
 `
