import tw, { styled } from "twin.macro"
import { Link } from "gatsby"

export const Button = styled(Link)`
  ${tw`
    bg-primary
    inline-block
    rounded-sm 
    px-4 
    my-4
    md:px-5 
    xl:px-4 
    py-3 
    md:py-4 
    xl:py-3 
    hover:bg-primary
    md:text-lg 
    xl:text-base-color 
    text-base-color 
    leading-tight 
    shadow-md
    uppercase
  `}
`
