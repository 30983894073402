import React from "react"
import { MDXProvider } from "@mdx-js/react"
import Header from "./header"
import { Footer } from "./footer"
import mdxComponents from "./mdxComponents"
import "../theme/globals.css"

const Layout: React.FC = ({ children }) => {
  return (
    <>
      <Header />
      <MDXProvider components={mdxComponents}>
        <main>{children}</main>
      </MDXProvider>
      <Footer />
    </>
  )
}

export default Layout
