import React from "react"
import tw, { styled } from "twin.macro"
import LogoLight from "../images/logo-light.svg"
import { H3, P } from "../components/page-elements"
import { Link } from "gatsby"

const Container = styled.footer`
  ${tw`
  relative
  pt-8
  md:pt-64
  overflow-hidden
  `}

  @media(min-width: 1280px) {
    padding-top: 300px;
  }

  @media (min-width: 1500px) {
    padding-top: 500px;
  }
`

const Col = tw.div`
    w-full
    mt-10
    md:py-8
    md:mt-0
    md:flex-1
`

const Row = styled.div`
  ${tw`
  px-page-gutter
  py-24
  md:py-page-gutter
  flex
  flex-wrap
  m-auto
  max-w-screen-xl
  `}
  @media(min-width: 1280px) {
    height: 400px;
  }
`

const QuickLink = styled(Link)`
  ${tw`text-base-color text-base my-1`}
`

const ListItem = styled(P)`
  ${tw`text-base-color text-base my-1`}
`

const Bottom = styled(P)`
  ${tw`mt-0 text-center text-body items-center content-center mt-0`}
`

export const DiagonalBox = styled.div`
  height: ${(props: { height: string }) =>
    props.height ? props.height : "600px"};
  position: absolute;
  background-image: linear-gradient(
    45deg,
    var(--color-primary) 50%,
    var(--color-secondary)
  );
  transform: skewY(-11deg);
  z-index: -10;
  width: 100%;
  left: 0;
`

const BoxContainer = styled.div`
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding-top: 150px;
  z-index: -1;

  @media (min-width: 1280px) {
    padding-top: 300px;
    height: 1000px;
  }
`

export const Footer: React.FC = () => {
  return (
    <Container>
      <BoxContainer>
        <DiagonalBox top="-10%" height="200%"></DiagonalBox>
      </BoxContainer>
      <Row>
        <Col>
          <img
            src={LogoLight}
            className="w-2/3 md:w-1/2 my-8"
            alt="Out Of Mind Web Design Logo Light"
          />
          <ListItem className="text-base-color">Chicago, IL</ListItem>
          <ListItem className="text-base-color">
            OutOfMindWebDesign@gmail.com
          </ListItem>
          <ListItem className="text-base-color">(312) 487-2032</ListItem>
        </Col>
        <Col>
          <H3 className="text-base-color mb-6 pb-6 border-b-2 border-secondary w-1/2">
            Quick Links
          </H3>
          <div className="flex flex-col">
            <QuickLink to="/">Home</QuickLink>
            <QuickLink to="/about">About</QuickLink>
            <QuickLink to="/services">Services</QuickLink>
            <QuickLink to="/our-work">Work</QuickLink>
            <QuickLink to="/contact">Contact</QuickLink>
            <QuickLink to="/blog">Blog</QuickLink>
          </div>
        </Col>
        <Col>
          <H3 className="text-base-color mb-6 pb-6 border-b-2 border-secondary w-1/2">
            Services
          </H3>
          <div className="flex flex-col">
            <ListItem>Web Design</ListItem>
            <ListItem>Website Maintenance</ListItem>
            <ListItem>SEO Services</ListItem>
            <ListItem>Content Creation</ListItem>
            <ListItem>Logo / Brand Design</ListItem>
            <ListItem>Google Ads</ListItem>
          </div>
        </Col>
      </Row>
      <div className="bg-secondary w-full p-2">
        <Bottom>
          Copyright © {new Date().getFullYear()} | Out Of Mind Web Design | All
          Rights Reserved
        </Bottom>
      </div>
    </Container>
  )
}
