import { styled } from "twin.macro"

export const LightDiagonalBox = styled.div`
  height: 600px;
  position: absolute;
  background-image: linear-gradient(
    45deg,
    rgba(var(--color-secondary-rgba), 0.5) 80%,
    #fff
  );
  transform: skewY(-11deg);
  z-index: -10;
  width: 100%;
  left: 0;
  top: ${(props: { top: string }) => props.top};

  @media (min-width: 1024px) {
    height: 800px;
  }
`
