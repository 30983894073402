import tw from "twin.macro"

export const H1 = tw.h1`
  text-3xl
  lg:text-6xl
  font-sans
  leading-none
  tracking-tight
  font-black
  mt-8
`
