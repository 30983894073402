import tw, { styled } from "twin.macro"

export const P = styled.p.attrs({
  className: "mt-3",
})`
  & {
    strong {
      ${tw`font-bold`}
    }
    em {
      ${tw`italic`}
    }
  }
`
