import React from "react"
import tw, { styled } from "twin.macro"
import { H1, H2, H3, P } from "../page-elements"

const BlogH3 = styled(H3)`
  ${tw`
    mb-8
    mt-10
    text-header
  
  `}
`

export default {
  h1: props => <H1 {...props} />,
  h2: props => <H2 as="h2" {...props} />,
  h3: props => <BlogH3 {...props} />,
  p: props => <P className="leading-relaxed" {...props} />,
}
