import React from "react"
import tw from "twin.macro"

interface HamburgerMenu {
  onClick(): void
}

const Button = tw.button`
  flex 
  items-center 
  md:hidden 
  text-base-color
  focus:outline-none 
  focus:text-gray-200
`

const SVG = tw.svg`
  fill-current
  w-10
  h-10
`

const HamburgerMenu: React.FC<HamburgerMenu> = ({ onClick }) => (
  <Button onClick={onClick} aria-label="Open site navigation" role="button">
    <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.3327 10H6.66602V15H33.3327V10ZM6.66602 18.3317H33.3327V23.3317H6.66602V18.3317ZM6.66602 26.665H33.3327V31.665H6.66602V26.665Z"
      ></path>
    </SVG>
  </Button>
)

export default HamburgerMenu
