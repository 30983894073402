import tw from "twin.macro"

export const H2 = tw.h2`
  text-2xl
  md:text-5xl
  md:mb-4
  font-sans
  leading-none
  tracking-tight
  font-black
  mt-6
`
