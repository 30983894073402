import { Link } from "gatsby"
import React, { useState } from "react"
import styled from "styled-components"
import tw from "twin.macro"
import HamburgerMenu from "./hamburger-menu"
import CloseIcon from "../images/close-icon.svg"
import LogoLightIcon from "../images/logo-light.svg"
import LogoDarkIcon from "../images/logo-dark.svg"
import { Logo } from "./styles/logo"

const StyledHeader = tw.header`
  flex
  justify-between
  mb-2
  h-20
  bg-transparent
  w-full
  md:m-auto
  md:max-w-screen-xl
`

const Nav = tw.nav`
  flex
  justify-between
  items-center
  w-full
  px-page-gutter  
`

const DesktopMenu = tw.div`
  hidden
  md:block
`

const A = styled(Link)(
  tw`
  flex
  items-center
  content-center
  no-underline
`
)

const MobileMenuDrawer = styled.div`
  ${tw`
    fixed
    top-0
    right-0
    bottom-0
    z-50
    overflow-hidden
    overflow-y-scroll	
    bg-white
    rounded-tl-md
    py-4
    w-4/5
  `}
  max-width: 400px;
  transition: transform 0.2s ease-in-out; ;
`

const Overlay = styled.div`
  ${tw`
    fixed
    top-0
    right-0
    bottom-0
    w-full
    z-50
  `}
  background: rgba(0,0,0,.3);
`

const MobileMenu = tw.div`
  w-full
  flex
  flex-col
`

const StyledLink = styled(Link)`
  ${tw`
    text-base
    text-base-color
    mx-4
    p-4
    md:text-lg
    md:font-bold
  `}
`

const MobileLink = styled(StyledLink)`
  ${tw`
    text-header
    rounded-md
    p-2
    
  `}
  &.active {
    background: var(--color-base);
    color: var(--color-primary);
  }
`

const DeskTopLink = styled(Link)`
  ${tw`
    text-base text-base-color mx-4 p-4 md:text-lg md:font-bold relative
  `}
  &:after {
    content: "";
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 100%;
    height: 3px;
    background: white;
    transition: 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    transform: scaleX(0);
  }
  &:hover:after {
    transform: scaleX(1);
  }
  &.active :after {
    transform: scaleX(1);
  }
`

const menuItems = [
  { text: "Home", url: "/" },
  { text: "About", url: "/about" },
  { text: "Services", url: "/services" },
  { text: "Work", url: "/#our-work" },
  { text: "Contact", url: "/contact" },
  { text: "Blog", url: "/blog" },
]

const Header: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)

  const handleToggleMenu = () => {
    setIsOpen(current => !current)
  }

  return (
    <StyledHeader>
      <Nav>
        <A to="/">
          <Logo src={LogoLightIcon} alt="Out Of Mind Web Design Logo Light" />
        </A>
        <DesktopMenu role="navigation" aria-label="Desktop Menu">
          {menuItems.map(({ url, text }) => (
            <DeskTopLink key={text} to={url} activeClassName="active">
              {text}
            </DeskTopLink>
          ))}
        </DesktopMenu>
        <HamburgerMenu onClick={handleToggleMenu} />
        {isOpen && <Overlay onClick={handleToggleMenu} isOpen={isOpen} />}
        <MobileMenuDrawer
          style={{ transform: isOpen ? "translateX(0)" : "translateX(100%)" }}
        >
          <div className="flex justify-between px-4 mb-4">
            <Logo src={LogoDarkIcon} alt="Out Of Mind Web Design Logo Dark" />
            <button
              onClick={handleToggleMenu}
              role="button"
              aria-label="Close Mobile Menu"
            >
              <img src={CloseIcon} className="w-6 h-6" alt="Close Icon" />
            </button>
          </div>
          <MobileMenu role="navigation" aria-label="Mobile Menu">
            {menuItems.map(({ url, text }) => (
              <MobileLink
                key={text}
                to={url}
                activeClassName="active"
                onClick={handleToggleMenu}
              >
                {text}
              </MobileLink>
            ))}
          </MobileMenu>
        </MobileMenuDrawer>
      </Nav>
    </StyledHeader>
  )
}

export default Header
