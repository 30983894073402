import { styled } from "twin.macro"

interface DiagonalBoxProps {
  height?: string
  top: string
}
export const DiagonalBox = styled.div`
  height: ${(props: DiagonalBoxProps) =>
    props.height ? props.height : "600px"};
  position: absolute;
  background-image: linear-gradient(45deg, var(--color-primary) 50%, #fff);
  transform: skewY(-11deg);
  z-index: -10;
  width: 100%;
  left: 0;
  top: ${(props: DiagonalBoxProps) => props.top};

  @media (min-width: 1024px) {
    height: 800px;
  }
`
