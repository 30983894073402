import tw from "twin.macro"

export const H3 = tw.h3`
  text-xl
  font-sans
  leading-none
  tracking-tight
  font-black
  mt-4
`
